import * as React from 'react'
import FontAwesomeIcon from 'components/FontAwesomeIcon'
import { library } from '@fortawesome/fontawesome-svg-core'

import { faAd } from '@fortawesome/free-solid-svg-icons/faAd'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight'
import { faBan } from '@fortawesome/free-solid-svg-icons/faBan'
import { faBarcode } from '@fortawesome/free-solid-svg-icons/faBarcode'
import { faCamera } from '@fortawesome/free-solid-svg-icons/faCamera'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons/faCaretDown'
import { faCaretRight } from '@fortawesome/free-solid-svg-icons/faCaretRight'
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle'
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons/faCheckSquare'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight'
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons/faCloudUploadAlt'
import { faCog } from '@fortawesome/free-solid-svg-icons/faCog'
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons/faEllipsisV'
import { faExclamation } from '@fortawesome/free-solid-svg-icons/faExclamation'
import { faFileCsv } from '@fortawesome/free-solid-svg-icons/faFileCsv'
import { faFileExcel } from '@fortawesome/free-solid-svg-icons/faFileExcel'
import { faFilter } from '@fortawesome/free-solid-svg-icons/faFilter'
import { faFireAlt } from '@fortawesome/free-solid-svg-icons/faFireAlt'
import { faFolderPlus } from '@fortawesome/free-solid-svg-icons/faFolderPlus'
import { faHeadset } from '@fortawesome/free-solid-svg-icons/faHeadset'
import { faHeart as fasHeart } from '@fortawesome/free-solid-svg-icons/faHeart'
import { faHistory } from '@fortawesome/free-solid-svg-icons/faHistory'
import { faImage } from '@fortawesome/free-solid-svg-icons/faImage'
import { faImages } from '@fortawesome/free-solid-svg-icons/faImages'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle'
import { faLink } from '@fortawesome/free-solid-svg-icons/faLink'
import { faList } from '@fortawesome/free-solid-svg-icons/faList'
import { faLock } from '@fortawesome/free-solid-svg-icons/faLock'
import { faLockOpen } from '@fortawesome/free-solid-svg-icons/faLockOpen'
import { faMinus } from '@fortawesome/free-solid-svg-icons/faMinus'
import { faMobileAlt } from '@fortawesome/free-solid-svg-icons/faMobileAlt'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons/faPaperPlane'
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons/faPlusCircle'
import { faQrcode } from '@fortawesome/free-solid-svg-icons/faQrcode'
import { faSchool } from '@fortawesome/free-solid-svg-icons/faSchool'
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch'
import { faShare } from '@fortawesome/free-solid-svg-icons/faShare'
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons/faSignInAlt'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons/faSignOutAlt'
import { faSlidersH } from '@fortawesome/free-solid-svg-icons/faSlidersH'
import { faSort } from '@fortawesome/free-solid-svg-icons/faSort'
import { faSortAlphaDown } from '@fortawesome/free-solid-svg-icons/faSortAlphaDown'
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner'
import { faStar as fasStar } from '@fortawesome/free-solid-svg-icons/faStar'
import { faStarHalfAlt } from '@fortawesome/free-solid-svg-icons/faStarHalfAlt'
import { faSync } from '@fortawesome/free-solid-svg-icons/faSync'
import { faTh } from '@fortawesome/free-solid-svg-icons/faTh'
import { faThLarge } from '@fortawesome/free-solid-svg-icons/faThLarge'
import { faThList } from '@fortawesome/free-solid-svg-icons/faThList'
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'
import { faTimesCircle as fasTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle'
import { faToggleOff } from '@fortawesome/free-solid-svg-icons/faToggleOff'
import { faToggleOn } from '@fortawesome/free-solid-svg-icons/faToggleOn'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons/faTrashAlt'
import { faUndo } from '@fortawesome/free-solid-svg-icons/faUndo'
import { faUserPlus } from '@fortawesome/free-solid-svg-icons/faUserPlus'
import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers'
import { faBook } from '@fortawesome/free-solid-svg-icons/faBook'
import { faBolt } from '@fortawesome/free-solid-svg-icons/faBolt'
import { faFlask } from '@fortawesome/free-solid-svg-icons/faFlask'

import { faBell } from '@fortawesome/free-regular-svg-icons/faBell'
import { faCalendar } from '@fortawesome/free-regular-svg-icons/faCalendar'
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons/faCalendarAlt'
import { faCircle } from '@fortawesome/free-regular-svg-icons/faCircle'
import { faClipboard } from '@fortawesome/free-regular-svg-icons/faClipboard'
import { faClock } from '@fortawesome/free-regular-svg-icons/faClock'
import { faComment } from '@fortawesome/free-regular-svg-icons/faComment'
import { faCreditCard } from '@fortawesome/free-regular-svg-icons/faCreditCard'
import { faDotCircle } from '@fortawesome/free-regular-svg-icons/faDotCircle'
import { faEdit } from '@fortawesome/free-regular-svg-icons/faEdit'
import { faEye } from '@fortawesome/free-regular-svg-icons/faEye'
import { faEyeSlash } from '@fortawesome/free-regular-svg-icons/faEyeSlash'
import { faFolder } from '@fortawesome/free-regular-svg-icons/faFolder'
import { faFolderOpen } from '@fortawesome/free-regular-svg-icons/faFolderOpen'
import { faHandPointRight } from '@fortawesome/free-regular-svg-icons/faHandPointRight'
import { faHandshake } from '@fortawesome/free-regular-svg-icons/faHandshake'
import { faHeart } from '@fortawesome/free-regular-svg-icons/faHeart'
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons/faQuestionCircle'
import { faSquare } from '@fortawesome/free-regular-svg-icons/faSquare'
import { faStar } from '@fortawesome/free-regular-svg-icons/faStar'
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons/faTimesCircle'
import { faUser } from '@fortawesome/free-regular-svg-icons/faUser'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons/faEnvelope'
import { faFileLines } from '@fortawesome/free-regular-svg-icons/faFileLines'
import { faHandHoldingHeart } from '@fortawesome/free-solid-svg-icons/faHandHoldingHeart'

library.add(
  faBarcode,
  faBell,
  faCalendar,
  faCalendarAlt,
  faCamera,
  faCaretRight,
  faCaretDown,
  faCheckSquare,
  faCheckCircle,
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faClock,
  faCloudUploadAlt,
  faCog,
  faComment,
  faEdit,
  faExclamation,
  faEye,
  faEyeSlash,
  faFileCsv,
  faFileLines,
  faFolder,
  faFolderOpen,
  faFolderPlus,
  faHistory,
  faImage,
  faImages,
  faMinus,
  faPlus,
  faPlusCircle,
  faQuestionCircle,
  faSearch,
  faShare,
  faSignInAlt,
  faSignOutAlt,
  faSpinner,
  faSquare,
  faSync,
  faTimes,
  faTimesCircle,
  faToggleOff,
  faToggleOn,
  faTrashAlt,
  faUndo,
  faUser,
  faUserPlus,
  faUsers,
  fasTimesCircle,
  faQrcode,
  faClipboard,
  faCheck,
  faHandPointRight,
  faCircle,
  faDotCircle,
  faSlidersH,
  faSortAlphaDown,
  faSort,
  faList,
  faHeart,
  fasHeart,
  faTh,
  faThList,
  faThLarge,
  faFireAlt,
  faEllipsisV,
  faFileExcel,
  faInfoCircle,
  faStar,
  fasStar,
  faStarHalfAlt,
  faLink,
  faFilter,
  faBan,
  faPaperPlane,
  faMobileAlt,
  faHeadset,
  faCreditCard,
  faHandshake,
  faSchool,
  faLock,
  faLockOpen,
  faArrowRight,
  faAd,
  faEnvelope,
  faBook,
  faBolt,
  faFlask,
  faHandHoldingHeart,
)

import { size as baseSize } from 'styles'
import useColorScheme from 'hooks/useColorScheme'
import { styles } from 'styles'
import { PropTypes } from 'prop-types'
import View from 'components/View'

const Icon = props => {

  const theme = useColorScheme()

  let {
    size = baseSize.md,
    color = styles.color[theme].onBackground,
    label,
    badge,
    danger,
    primary,
    containerStyle,
    name,
    regular,
    ...restConst
  } = props

  const {
    xs,
    sm,
    lg,
    xl,
    xxl,
    inverted,
    ...rest
  } = restConst

  if(xs) size = baseSize.xs
  if(sm) size = baseSize.sm
  if(lg) size = baseSize.lg
  if(xl) size = baseSize.xl
  if(xxl) size = baseSize.xxl
  if(inverted) color = '#fff'
  if(label) color = styles.color.grey
  if(danger) color = styles.color[theme].error
  if(primary) color = styles.color[theme].primary

  if(props.color) color = props.color

  // size can be number or 'sm', 'lg' string
  if(props.size && typeof props.size === 'string'){
    size = baseSize[props.size]
  }

  if(xs || sm || lg || xl || xxl){
    ppWarn('Icon.DEPRECATED: use size', {
      name: props.name,
      xs, sm, lg, xl, xxl
    })
  }

  let prefix = 'fas'
  if(regular) prefix = 'far'

  return (
    <View center style={{
      width: size+5, height: size,
      ...containerStyle,
    }}>
      {badge ? (
        <View style={{
          ...styles.fixed.topRight,
          top: -4, right: -4,
          zIndex: 1,
          borderRadius: 6,
          width: 12,
          height: 12,
          backgroundColor: styles.color[theme].error,
        }} />
      ) : null}
      <FontAwesomeIcon
        size={size}
        color={color}
        icon={[prefix, name]}
        {...rest}
      />
    </View>
  )

}

Icon.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.string,
  label: PropTypes.bool,
  badge: PropTypes.bool,
  primary: PropTypes.bool,
  danger: PropTypes.bool,
  containerStyle: PropTypes.object,
  name: PropTypes.string.isRequired,
  regular: PropTypes.bool,
}

export default Icon
