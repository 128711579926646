import { useState, useEffect } from 'react'
import {
  View,
  StyleSheet,
  ImageResizeMode,
  ImageSourcePropType,
} from 'react-native'
import { colors } from './Text'
import NativeImage from '@/components/NativeImage'
import WebImage from '@/components/WebImage'
import FontIcon from '@/components/FontIcon'

import Platform from '@/lib/Platform'
import { color } from '@/styles/constants'
import Configs from '@/configs/Configs'
import { useSelector } from '@/store'
import { capture } from '@/sentry'

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  }
})

export const bgColor = color.grey[100]

type Props = {
  id?: number,
  uri?: string | number,
  path?: string,
  style?: object,
  backgroundColor?: string,
  resizeMode?: ImageResizeMode,
  isFullSize?: boolean,
  onLoadEnd?: ()=>void,
}
const Image = ({
  id,
  uri,
  path,
  style,
  backgroundColor = bgColor,
  resizeMode,
  isFullSize,
  onLoadEnd,
  ...rest
}: Props) => {

  const image = useSelector(state => {
    if(!id) return null
    return state.entities?.images?.[id] || null
  })
  const [source, setSource] = useState<ImageSourcePropType|undefined>(undefined)
  const [isReady, setIsReady] = useState(false)

  useEffect(()=> {
    let initialSource
    if(image){ // when the image id is given

      if(isFullSize){
        if(image.public_url){
          initialSource = { uri: image.public_url }
        } else {
          initialSource = { uri: Configs.baseUrl + image.path }
        }
      }else if(image.thumbnail_public_url){
        initialSource = { uri: image.thumbnail_public_url }
      }else if(image.thumbnail_path){
        initialSource = { uri: Configs.baseUrl + image.thumbnail_path }
      }else if(image.public_url){
        initialSource = { uri: image.public_url }
      }else{
        initialSource = { uri: Configs.baseUrl + image.path }
      }
    }

    // those will overwrite if given with id
    if(path){ // can be directly given from EditGood
      initialSource = { uri: Configs.baseUrl + path }
    }else{
      if(typeof uri === 'string' && uri.length > 0){
        initialSource = { uri: uri }
      }else if(typeof uri === 'number'){
        initialSource = uri
      }
    }
    setSource(initialSource)
    setIsReady(true)
  }, [id, path, uri])

  const isNoImage = !image?.id && !path && !uri

  const handleError = (e: any) => {
    if(image && image.thumbnail_path){
      setSource({ uri: Configs.baseUrl + image.path })
    }
    capture(e)
  }

  const showIcon = isReady && (!source || isNoImage)

  return (
    <View style={[
      styles.container,
      { backgroundColor },
      style,
    ]}>
      {showIcon ? (
        <FontIcon name='image-light' size='md' color={colors.label} />
      ) : (
        Platform.OS === 'web' ? (
          <WebImage
            resizeMode={resizeMode}
            source={source}
            onError={handleError}
            style={style}
            {...rest}
          />
        ) : (
          <NativeImage
            style={{
              width: '100%',
              height: '100%',
            }}
            resizeMode={resizeMode}
            source={source}
            onError={handleError}
            onLoadEnd={onLoadEnd}
            {...rest}
          />
        )
      )}
    </View>
  )
}

export default Image
