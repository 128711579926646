import React from 'react'
import { Text as NativeText } from 'react-native'
import AutoLink from 'react-native-autolink'
import { styles } from 'styles'
import useColorScheme from 'hooks/useColorScheme'

export const colors = {
  label: styles.color.grey,
  danger: styles.color.danger,
  white: styles.color.white,
}

const Text = props => {
  const theme = useColorScheme()

  let {
    color = styles.color[theme].onBackground,
    ...restConst
  } = props

  const {
    // DEPRECATED
    xxs,
    xs,
    sm,
    lg,
    xl,
    xxl,
    // In use
    size,
    inverted,
    danger,
    bold,
    style,
    children,
    label,
    wrap,
    link,
    shadow,
    center,
    end,
    autolink,
    selectable,
    ...rest
  } = restConst

  let fontSize = styles.size.md
  if(xxs) fontSize = styles.size.xxs
  if(xs) fontSize =  styles.size.xs
  if(sm) fontSize =  styles.size.sm
  if(lg) fontSize =  styles.size.lg
  if(xl) fontSize =  styles.size.xl
  if(xxl) fontSize = styles.size.xxl
  if(link) color = styles.color[theme].primary
  if(label) color = styles.color[theme].label
  if(inverted) {
    color = styles.color[theme].background
  }

  if(size) fontSize = styles.size[size]

  if(danger) color = colors.danger

  if(xxs || xs || sm || lg || xl || xxl){
    ppWarn(`Text.DEPRECATED: use size='sm|md...'`)
  }

  if(autolink && typeof children === 'string'){
    return(
      <AutoLink
        style={{
          color,
          fontSize,
          // not sure if it does anything
          alignItems: 'center',
          // avoid text element to change it's height by font type
          // those height setting was making it difficuly to align items
          // minHeight: fontSize * 1.5,
          // lineHeight: fontSize * 1.25,
          ...(bold  ? { fontWeight: 'bold' } : {}),
          ...(wrap ? { flexShrink: 1 } : {}),
          ...(center ? { textAlign: 'center' } : {}),
          ...(end ? {
            textAlign: 'right',
            justifyContent: 'flex-end',
          } : {}),
          ...(link ? {
            textDecorationLine: 'underline',
            textDecorationStyle: 'solid',
            textDecorationColor: color,
          } : {}),
          ...(shadow && {
            textShadowColor: "#000",
            textShadowOffset: { width: 0, height: 0 },
            textShadowRadius: 5,
          }),
          ...style,
        }}
        text={children}
        textProps={rest}
        selectable={true}
        linkStyle={{ color: styles.color[theme].primary }}
      />
    )
  }

  return (
    <NativeText
      style={{
        color,
        fontSize,
        // not sure if it does anything
        alignItems: 'center',
        // avoid text element to change it's height by font type
        // those height setting was making it difficuly to align items
        // minHeight: fontSize * 1.5,
        // lineHeight: fontSize * 1.25,
        ...(bold  ? { fontWeight: 'bold' } : {}),
        ...(wrap ? { flexShrink: 1 } : {}),
        ...(center ? { textAlign: 'center' } : {}),
        ...(end ? {
          textAlign: 'right',
          justifyContent: 'flex-end',
        } : {}),
        ...(link ? {
          textDecorationLine: 'underline',
          textDecorationStyle: 'solid',
          textDecorationColor: color,
        } : {}),
        ...(shadow && {
          textShadowColor: "#000",
          textShadowOffset: { width: 0, height: 0 },
          textShadowRadius: 5,
        }),
        ...style,
      }}
      selectable={selectable}
      {...rest}
    >
      {children}
    </NativeText>
  )

}

export default Text
