import DateTime from '@/lib/DateTime'
import { sync } from '@/features/sync'
import { flash } from '@/features/flash'
import { t } from '@/i18n'
import { array } from '@/utils/array'
import { Booking } from '@/types'
import { Dispatch, GetState, RootState } from '@/store'
import jwt from '@/utils/jwt'
import utils from '@/utils'

type ScheduleItem = {
  remaining: number,
  disabled: boolean,
  marked: boolean,
}

export type Schedule = {
  [key: string]: ScheduleItem
}

export type PublicBooking = {
  id: string,
  start_at: string,
  end_at: string,
  quantity: number,
  returned_at?: string,
  cancelled?: boolean,
  locked_from: string,
  locked_to: string
}

interface Good {
  quantity: number,
  bookings: PublicBooking[],
}

const scheduleIsSame = (prev:ScheduleItem, next:ScheduleItem) => {
  return prev?.remaining === next?.remaining &&
    prev?.disabled === next?.disabled &&
    prev?.marked === next?.marked
}
export const schedulesAreEqual = (prev: Schedule, next:Schedule) => {
  const prevKeys = Object.keys(prev)
  const nextKeys = Object.keys(next)
  if(prevKeys.length !== nextKeys.length) return false
  return prevKeys.every(key=> scheduleIsSame(prev[key], next[key]))
}

export const goodsToSchedule = (goods: Good[]) => {
  // loop through all the goods
  const schedules: Schedule[] = []
  const quantities: number[] = []
  goods.forEach(good => {
    quantities.push(good.quantity)
    schedules.push(toSchedule({
      data: good.bookings,
      quantity: good.quantity,
    }))
  })

  const minGoodsQuantity = Math.min(...quantities)

  // merge schedules and return one schedule
  const mergedSchedule: Schedule = {}
  schedules.forEach(schedule => {
    Object.keys(schedule).forEach(key => {
      if(!mergedSchedule[key]) mergedSchedule[key] = schedule[key]

      // remaining: the minimum remaining quantity
      const minRemaining = Math.min(
        minGoodsQuantity,
        mergedSchedule[key].remaining,
        schedule[key].remaining,
      )
      mergedSchedule[key].remaining = minRemaining

      // disabled: true if any schedule is disabled
      const disabled = mergedSchedule[key].disabled || schedule[key].disabled
      mergedSchedule[key].disabled = disabled

      // marked: true if any schedule is marked, false if disabled
      mergedSchedule[key].marked =
        mergedSchedule[key].marked || schedule[key].marked

      if(disabled) mergedSchedule[key].marked = false
    })
  })
  return mergedSchedule
}

// converting bookings to schedule
// overwrapped period will be added up
//
// data: [ { locked_from, locked_to, quantity, returned_at, cancelled } ]
// schedule: { [date]: { remaining, disabled } }
export const toSchedule = ({
  data,
  quantity,
}: {
  data: PublicBooking[],
  quantity: number,
}) => {
  if(!data) return {}

  const schedule: Schedule = {}
  data.forEach(booking => {
    if(booking.cancelled) return

    // pp('booking', { locked_from: booking.locked_from, locked_to: booking.locked_to, returned_at: booking.returned_at })

    const start = DateTime.fromISO(booking.locked_from)
    let end = DateTime.fromISO(booking.locked_to)
    const returned_at = booking.returned_at ? DateTime.fromISO(booking.returned_at) : null

    if(returned_at && returned_at < end) end = returned_at

    // loop start from start date and increment by 1 day
    // until it reaches end date
    let date = start
    while(date <= end){
      // end could be like 12:00, and it should not be counted as used if the date
      // is the same date as returned_at since it is already returned on the day.
      if(returned_at?.toFormat?.('yyyy-MM-dd') === date.toFormat('yyyy-MM-dd')) return

      // check if the start is earlier or equal to the start of date
      // check if the end is more thant the end of this date
      // if so, it means that the booking covers the whole day
      const isStartedBeforeThisDate = start.toUnixInteger() <= date.startOf('day').toUnixInteger()
      const isEndedAfterThisDate    = date.endOf('day').toUnixInteger() <= end.toUnixInteger()
      const isWholeDay = isStartedBeforeThisDate && isEndedAfterThisDate
      // pp({start: start.toISO(), end: end.toISO(), date: date.toISO(), returned_at: returned_at?.toISO?.()})

      const key = date.toFormat('yyyy-MM-dd')
      const bookingQuantity = booking.quantity || 0
      let remaining = quantity
      if (schedule[key]) {
        remaining = schedule[key].remaining
      }
      remaining = remaining - bookingQuantity

      const disabled = remaining <= 0 && isWholeDay
      // place a mark if any amount is used
      let marked = remaining !== quantity
      if(disabled) marked = false // don't mark disabled dates

      schedule[key] = { remaining, disabled, marked }

      date = date.plus({ days: 1 })
    }
  })

  return schedule
}

// returns min remaining amount in the given period
// if there is no schedule, it returns null
// FIXME: It works fine on for calendar since when the disabled days are not
// selectable, but getMinRemaining 0 is also falsy, so it is not a good solution.
export const getMinRemaining = ({
  schedule,
  from,
  to,
}: {
  schedule: Schedule,
  from: string,
  to: string,
}) => {
  const fromDate = DateTime.local(
    parseInt(from.substring(0, 4)),
    parseInt(from.substring(5, 7)),
    parseInt(from.substring(8, 10)),
    0, 0, 0
  )
  const toDate   = DateTime.local(
    parseInt(to.substring(0, 4)),
    parseInt(to.substring(5, 7)),
    parseInt(to.substring(8, 10)),
    0, 0, 0
  )

  const dateDiff = toDate.diff(fromDate, 'days')
  const days = dateDiff.values.days + 1

  // start from max quantity and add depending on the data
  const remainings: number[] = []
  // start from day zero to the end of the day
  Array.from(Array(days)).forEach((_, i)=> {
    const targetDate = fromDate.plus({ days: i }).toISODate()
    const remaining = schedule[targetDate]?.remaining
    if(remaining){
      remainings.push(remaining)
    }
  })

  // when the schedule does not exists, return null, use max available quantity
  if(remainings.length === 0) return null

  return Math.min(...remainings)

}

const toggleReturned = (bookingId:string)=> {
  return async(dispatch: Dispatch, getState: GetState) => {

    const isReturned = !!getState().entities?.bookings[bookingId].returned_at

    const response = await sync.update({
      entity: 'booking',
      id: bookingId,
      payload: { returned_at: isReturned ? null : Date.now() },
    })(dispatch, getState)
    if(response.ok){
      if(isReturned){ // returned -> not returned
        flash.add({ type: 'success', body: t('flash.updated') })(dispatch)
      }else{ // not returned -> returned
        flash.add({ type: 'success', body: t('flash.booking.returned') })(dispatch)
      }
    }
  }
}


const startAtDateTimeFromParams = (params: {
  date: string,
  startAt: string,
}) => {
  const { date, startAt } = params
  if(!date || !startAt) return null
  return DateTime.fromISO(`${date}T${startAt}`)
}

const endAtDateTimeFromParams = (params: {
  date: string,
  endAt: string,
  duration: number,
}) => {
  const { date, endAt, duration } = params
  if(!date || !endAt || !duration) return null
  const toDate = DateTime.fromISO(`${date}`).plus({ days: duration - 1 })
  return DateTime.fromISO(`${toDate.toFormat('yyyy-MM-dd')}T${endAt}`)
}

const hoursFromParams = (params: {
  startAt: string,
  endAt: string,
  date: string,
  duration: number,
}) => {
  let hours = 0
  if(params.startAt && params.endAt){ // when time is specified
    const from = startAtDateTimeFromParams(params)
    const to = endAtDateTimeFromParams(params)

    if(from && to){
      hours = to.diff(from, 'hours').hours
    }

  }else{ // when only dates are specified
    const from = startAtDateTimeFromParams({
      ...params, startAt: '00:00'
    })

    if(isNaN(params.duration) || params.duration <= 0) return 0
    const nextToEndDate = DateTime.fromISO(`${params.date}`).plus({ days: params.duration })
    const to = DateTime.fromISO(`${nextToEndDate.toFormat('yyyy-MM-dd')}T00:00`)

    if(from && to){
      hours = to.diff(from, 'hours').hours
    }
  }

  if(hours <= 0) hours = 0
  return hours
}

// TODO: hasAnyStatus will return true when there are
// any payments, however, it may not have any status
// when all the payments were succeeded.
// make a special kind of selector to correctly return
// the result.
const hasAnyStatus = (booking: Booking) => {
  if(!booking) return false
  const any = booking.cancelled || booking.returned_at || booking.overdue_in_days || booking.payments?.length > 0 || booking.usage_status === 'handed_over' || booking.status === 'in_dispute' || booking.method === 'takeout'
  return any ? true : false
}

export const selectIsManaged = (
  state: RootState,
  bookingId: string,
): boolean => {
  const userId = state.entities?.bookings[bookingId]?.user
  const goodId = state.entities?.bookings[bookingId]?.good

  const parsed = jwt.parse(state.auth.token)
  const tokenSlug = parsed ? parsed.slug : null
  const currentUserId = state.auth.id || tokenSlug

  const ownerId = state.entities?.goods[goodId]?.user

  const managingUsers = state.entities?.users[currentUserId]?.managing_users || []

  // booking header could be shown to the member of the group if group.member_booking_visible is true
  const isManaged = utils.hasSameId(currentUserId, userId) ||
    utils.hasSameId(currentUserId, ownerId) ||
    managingUsers.includes(ownerId) ||
    managingUsers.includes(userId)
  return isManaged
}

export const togglePickedId = (pickedId: string)=> {
  return (dispatch: Dispatch, getState: GetState) => {
    dispatch({ type: types.togglePickedId, pickedId })
  }
}

export const clearPickedIds = ()=> {
  return (dispatch: Dispatch, getState: GetState) => {
    dispatch({ type: types.clearPickedIds })
  }
}

export const bookings = {
  hoursFromParams,
  toggleReturned,
  hasAnyStatus,
  toSchedule,
  getMinRemaining,
  goodsToSchedule,
}

const initialState = {
  pickedIds: []
}

const types = {
  togglePickedId: 'bookings/togglePickedId',
  clearPickedIds: 'bookings/clearPickedIds',
} as const

type TogglePickedIdAction = {
  type: 'bookings/togglePickedId',
  pickedId: string,
}
type ClearPickedIdsAction = {
  type: typeof types.clearPickedIds,
}

type Action = TogglePickedIdAction | ClearPickedIdsAction

export default (state = initialState, action: Action) => {
  switch(action.type){
    case types.togglePickedId: {
      const { pickedId } = action
      const found = state.pickedIds.some(id => id === pickedId)
      let nextIds = []
      if(found) { // remove if found
        nextIds = state.pickedIds.filter(id => id !== pickedId)
      }else{ // add if nto found
        nextIds = [...state.pickedIds, pickedId]
      }
      return{
        ...state,
        pickedIds: array.uniq(nextIds)
      }
    }
    case types.clearPickedIds: {
      return{
        ...state,
        pickedIds: []
      }
    }
    default:
      return state
  }
}
