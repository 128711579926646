import { PropTypes } from 'prop-types'

import { StyleSheet, View } from 'react-native'
import Text from 'components/Text'
import Help from 'components/Help'

import { color } from 'styles'
import { space } from 'styles/constants'
import useColorScheme from 'hooks/useColorScheme'
import { t } from '@/i18n'

const styles = StyleSheet.create({
  container: {
    marginTop: space[1],
    marginBottom: space[0.5],
    flexDirection: 'row',
    alignItems: 'center',
  },
  optional: {
    marginLeft: space[1],
  },
})

const Label = props => {

  const theme = useColorScheme()

  const {
    text,
    inverted,
    containerStyle,
    required,
    optional,
    help,
    link,
    size='sm'
  } = props

  return (
    <View style={[styles.container, containerStyle]}>
      <Text label size={size}
        inverted={inverted}
        shadow={inverted}
        link={link}
      >
        {text}
      </Text>

      {required ? (
        <View style={styles.optional}>
          <Text size={size} color={color[theme].error}>{t('general.required')}</Text>
        </View>
      ) : null}

      {optional ? (
        <View style={styles.optional}>
          <Text size={size} color={color.grey}>({t('general.optional')})</Text>
        </View>
      ) : null}

      <Help help={help} size={size} />
    </View>
  )

}

Label.propTypes = {
  text: PropTypes.string.isRequired,
  inverted: PropTypes.bool,
  containerStyle: PropTypes.object,
  required: PropTypes.bool,
  help: PropTypes.shape({
    title: PropTypes.string,
    body: PropTypes.string,
  }),
}

export default Label
