import { configureStore } from '@reduxjs/toolkit'
// middleware types

import {
  useStore as useReduxStore,
  useSelector as useReduxSelector,
  useDispatch as useReduxDispatch,
} from 'react-redux'
import {
  createSelector as createReSelector,
} from '@reduxjs/toolkit'

import { persistStore, persistReducer } from 'redux-persist'
import AsyncStorage from '@/lib/AsyncStorage'
import rootReducer from '@/reducers'

import type { Action, Reducer } from "redux"
import type { PersistConfig, PersistState } from "redux-persist"
declare module "redux-persist" {
  export function persistReducer<S, A extends Action = Action, P = S>(
    config: PersistConfig<S>,
    baseReducer: Reducer<S, A, P>,
  ): Reducer<
    S & { _persist: PersistState },
    A,
    P & { _persist?: PersistState }
  >
}

const persistConfig = {
  key: 'root',
  version: 1, // データの構造が変わった時などに利用する
  storage: AsyncStorage,
  // auth is loaded via hooks/useAuth
  // auth is stored via features/auth#setAuth
  blacklist: [
    'auth',
    'entities',
    'flash',
    'sync',
    'errors',
    'goods',
    'networks',
    'ads',
  ],
  //throttle: 100,
}

// rootReducerを永続化する
const persistedReducer = persistReducer(
  persistConfig,
  rootReducer
)

// 永続化されたReducerと他のミドルウェアからStoreを作成する
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware)=> getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE'], // Ignore specific actions from serialization checks
    },
  }),
})

// <PersistGate> コンポーネントで利用
export const persistor = persistStore(store)

// Infer the `RootState` and `Dispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type Dispatch = typeof store.dispatch
export type GetState = ()=> RootState

// directly using { useStore } from 'react-redux' will not have correct type definitions
export const useStore = ()=> {
  const { dispatch, getState } = useReduxStore()
  return {
    dispatch: dispatch as Dispatch,
    getState: getState as GetState,
  }
}

export const useDispatch = ()=> useReduxDispatch<Dispatch>()

export const useSelector = (
  selector: (state: RootState)=> any,
  equalityFn?: (left: any, right: any)=> boolean
)=> useReduxSelector(selector)

export const createSelector = createReSelector.withTypes<RootState>()

// 起動時にstoreから読み取れないなど何かしらの問題が発生した時はpurgeする
// persistor.purge()
