import { useState, useEffect, useRef } from 'react'
import SearchForm from 'components/searches/Form'
import { useNavigation } from '@react-navigation/native'
import { useSelector, useStore } from 'react-redux'
import { setValue } from 'features/searches'
import { t } from '@/i18n'

const GoodsSearchForm = () => {

  const navigation = useNavigation()
  const goodIds = useSelector(state => state.searches.goodIds)
  const { dispatch } = useStore()
  const ref = useRef(null)
  const storedValue = useSelector(state => state.searches.value)
  const [v, setV] = useState('')

  useEffect(()=> {
    if(!storedValue){
      setTimeout(()=> {
        ref.current?.focus()
      }, 250)
    }else{
      setV(storedValue)
    }
  }, [])

  useEffect(()=> {
    if(goodIds.length > 0){
      ref.current?.blur()
    }
  }, [goodIds])

  const onChangeText = (v) => {
    setV(v)
  }

  const onSubmit = async()=> {
    const newValue = `${v}`
    setValue(newValue)(dispatch)
    // search request is handled by useEffect in SearchScreen
    // always run search request on submit
    navigation.navigate('Search', { query: newValue, id: Math.random() })
  }

  return (
    <SearchForm id='form-search'
      ref={ref}
      onChangeText={onChangeText}
      value={v}
      onSubmit={onSubmit}
      placeholder={t('placeholders.good.search')}
    />
  )

}

GoodsSearchForm.propTypes = {

}

export default GoodsSearchForm
