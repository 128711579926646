import React from 'react'
import TouchableOpacity from 'components/TouchableOpacity'
import Text from './Text'
import View from './View'
import ActivityIndicator from 'components/ActivityIndicator'
import { StyleSheet } from 'react-native'

import { styles } from 'styles'
import useColorScheme from 'hooks/useColorScheme'
import { useLinkTo } from '@react-navigation/native'
import { parseToPath } from 'navigation/LinkingConfiguration'
import analytics from 'lib/analytics'
import { space, color, width } from 'styles/constants'

const buttonStyles = StyleSheet.create({
  button: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    borderRadius: space[0.5],
    padding: space[1],
  },
  leftItem: {
    marginRight: space[0.5],
  },
  base: {
    backgroundColor: color.brand,
    borderColor: color.brand,
  },
  inverted: {
    backgroundColor: '#fff',
    borderColor: color.brand,
  },
  danger: {
    backgroundColor: '#fff',
    borderColor: color.red[600],
  },
  disabled: {
    backgroundColor: color.grey[500],
    borderColor: color.grey[500],
  }
})

const Button = props => {
  const theme = useColorScheme()
  const linkTo = useLinkTo()

  const {
    onPress = ()=>{},
    style,
    leftIcon,
    label,
    inverted,
    loading,
    marginless,
    borderless,
    to,
    params,
    danger,
    nativeID,
    size='md',
  } = props

  const disabled = loading || props.disabled

  let textInverted = true
  let textColor = '#000'

  // make it visible
  if(inverted) {
    textInverted = false
    textColor = color.brand
  }
  if(danger)   textColor = color.red[600]
  if(disabled) textColor = '#fff'

  const handlePress = ()=> {
    if(to){
      linkTo(parseToPath(to, params))
      onPress?.()
    }else{
      onPress?.()
    }
  }

  return (
    <TouchableOpacity
      nativeID={nativeID}
      onPress={disabled ? ()=> {} : handlePress}
      activeOpacity={disabled ? 1 : 0.4}
      style={[
        buttonStyles.button,
        {
          minHeight: styles.size[size] * 2,
          borderWidth: borderless ? 0 : width.hairline,
          ...(marginless ? {} : { marginTop: space[2] }),
        },
        buttonStyles.base,
        inverted ? buttonStyles.inverted : {},
        danger ? buttonStyles.danger : {},
        disabled ? buttonStyles.disabled : {},
        style,
      ]}
    >
      {loading ? (
        <View style={buttonStyles.leftItem}>
          <ActivityIndicator
            inverted={textInverted}
          />
        </View>
      ) : null}
      {leftIcon ? (
        <View style={buttonStyles.leftItem}>
          {leftIcon}
        </View>
      ) : null}
      <Text
        inverted={textInverted}
        color={textColor}
        size={size}
      >
        {label}
      </Text>
    </TouchableOpacity>
  )

}

import { PropTypes } from 'prop-types'
Button.propTypes = {
  label: PropTypes.string, // could be icon only
  loading: PropTypes.bool.isRequired, // make it user friendly
  disabled: PropTypes.bool,
  onPress: PropTypes.func,
  leftIcon: PropTypes.object,
  style: PropTypes.object,
  inverted: PropTypes.bool,
  to: PropTypes.string,
  params: PropTypes.object,
  marginless: PropTypes.bool,
  borderless: PropTypes.bool,
  danger: PropTypes.bool,
  nativeID: PropTypes.string,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl'])
}

export default Button
