import 'polyfill'

import Sentry from 'lib/Sentry'
import StatusBar from 'components/StatusBar'
import { useEffect, useState } from 'react'
import Configs from 'configs/Configs'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import RootErrorBoundary from './RootErrorBoundary'
import KeyboardNav from 'components/KeyboardNav'
import AppBanner from 'components/AppBanner'

import useColorScheme from './hooks/useColorScheme'
import Navigation from './navigation'

// Redux 関連
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/lib/integration/react'
import { store, persistor } from './store'
import AnnouncementContainer from 'components/announcements/Container'

// When the app is opend with a link using scheme.
import useLinkHandler from 'hooks/useLinkHandler'
import { initLocale } from '@/i18n'

// When the app is opend with push notification.
import useNotificationHandler from 'hooks/useNotificationHandler'

import AuthGate from 'components/AuthGate'
import AuthSync from 'components/auth/AuthSync'
import useStagingLock from 'hooks/useStagingLock'

// A/B Testing
import {
  growthbook,
  GrowthBookProvider,
  getGA4Id,
  getId,
} from 'lib/GrowthBook'

import { useFonts } from 'expo-font'
import * as SplashScreen from 'expo-splash-screen'

import { enableScreens } from 'react-native-screens'
enableScreens(true)

import { GestureHandlerRootView } from 'react-native-gesture-handler'

import {decode, encode} from 'base-64'

if (!global.btoa) {
  global.btoa = encode;
}

if (!global.atob) {
  global.atob = decode;
}

const noop = ()=>{}
if (Configs.env === 'production') {
  console.log = noop
  console.warn = noop
  console.error = noop

  global.p = noop
  global.pWarn = noop
  global.pError = noop

  global.pp = noop
  global.ppWarn = noop
  global.ppError = noop
}else{
  // 開発環境では全てのログを表示する
  // p はconsole.logのalias
  global.p = noop//console.log
  global.p = console.log
  global.pWarn = console.warn
  global.pError = console.error

  // pp はテスト環境でも表示できるconsole.log (jest-setup.jsで設定)
  global.pp = console.log
  global.ppWarn = console.warn
  global.ppError = console.error
}

console.log('__DEV__', __DEV__)

import WebBrowser from 'lib/WebBrowser'
WebBrowser.maybeCompleteAuthSession()

import MobileAds from 'lib/MobileAds'//'react-native-google-mobile-ads'

MobileAds().initialize()

const App = () => {

  const colorScheme = useColorScheme()
  const { allowed } = useStagingLock()
  const [ isReady, setIsReady ] = useState(false)

  const [fontsLoaded] = useFonts({
    'fontawesome6': require('assets/fontawesome6.ttf'),
    'fontello': require('@/assets/fonts/fontello.ttf'),
  })

  useLinkHandler(store)
  useNotificationHandler()

  useEffect(()=> {
    init()
    initLocale()
    setupGrowthBook()
  }, [])

  useEffect(()=> {
    if(fontsLoaded){
      complete()
    }
  }, [fontsLoaded])

  const init = async()=> {
    // Keep the splash screen visible while we fetch resources
    await SplashScreen.preventAutoHideAsync()
  }

  const setupGrowthBook = async()=> {
    // setup A/B Testing with GA4 id
    const ga4Id = getGA4Id()
    const id = await getId()

    if(id){
      growthbook.setAttributes({ id })
    }
    if(ga4Id){
      growthbook.setAttributes({ clientId: ga4Id })
    }

    await growthbook.loadFeatures()
    setIsReady(true)
  }

  const complete = async()=> {
    await SplashScreen.hideAsync()
  }

  if(!allowed || !isReady) return null

  return (
    <GestureHandlerRootView style={{flex: 1}}>
      <GrowthBookProvider growthbook={growthbook}>
        <AppBanner />
        <RootErrorBoundary>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor} >
              {/* AuthGate will wait for render untill token to be loaded */}
              <AuthGate>
                <SafeAreaProvider>
                  <StatusBar />
                  <AnnouncementContainer />
                  <Navigation colorScheme={colorScheme} />
                  <KeyboardNav />
                </SafeAreaProvider>
              </AuthGate>
            </PersistGate>
          </Provider>
        </RootErrorBoundary>
      </GrowthBookProvider>
    </GestureHandlerRootView>
  )
}

export default Sentry.wrap(App)
